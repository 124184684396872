@use "src/styles/breakpoints";
@use "src/styles/constants";

.container {
  display: flex;
  flex-direction: column;
  background: var(--color-background-elevated);
  position: fixed;
  height: 100vh;
  width: 100%;
  inset-block: 0;
  z-index: var(--z-index-header);
  transition: var(--transition-regular);
  transition-timing-function: ease-in;
  overflow-y: auto !important;
  overflow-x: hidden;
  overscroll-behavior-y: contain !important;

  @include breakpoints.tablet {
    width: constants.$side-menu-desktop-width;
  }

  &.left {
    inset-inline-start: -100%;

    @include breakpoints.tablet {
      inset-inline-start: calc(-1 * constants.$side-menu-desktop-width);
    }
  }

  &.right {
    inset-inline-end: -100%;

    @include breakpoints.tablet {
      inset-inline-end: calc(-1 * constants.$side-menu-desktop-width);
    }
  }
}

.containerOpen {
  box-shadow: var(--shadow-normal);
  transition-timing-function: ease-out;

  &.left {
    inset-inline-start: 0;
  }

  &.right {
    inset-inline-end: 0;
  }
}

.searchContainer {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - calc(var(--navbar-container-height) + calc(4 * var(--spacing-mega))));

  @include breakpoints.tablet {
    min-height: calc(100% - calc(var(--navbar-container-height) + var(--spacing-small)));
  }

  justify-content: space-between;
}

.bodyContainer {
  flex: 1;
  margin-block-start: var(--navbar-container-height);
  padding-block-start: var(--spacing-small);
  padding-inline-start: var(--spacing-small);
  padding-inline-end: var(--spacing-small);
}

.bodyWithBottomPadding {
  padding-block-end: calc(4 * var(--spacing-large));
}

.navigationBodyContainer {
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.header {
  font-size: var(--font-size-large);
  width: 100%;
  position: fixed;
  height: var(--navbar-container-height);
  border-block-end: 1px var(--color-borders-hairline) solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoints.tablet {
    width: constants.$side-menu-desktop-width;
  }

  z-index: var(--z-index-header);
}

.headerContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #02989a;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: var(--spacing-xsmall);
  margin-inline-end: var(--spacing-xsmall);
}

.hiddenButtonHeader {
  border-block-end: 0;
}

.hiddenButtonHeaderContentContainer {
  align-items: flex-end;
}

.navbarInvisible {
  transform: translate3d(0, 0 + var(--navbar-container-height), 0);
}