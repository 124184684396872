@use "src/styles/utility";
@use "src/styles/breakpoints";

.container {
  @include utility.center-vertically;

  margin-inline-start: var(--spacing-xxsmall);

  @include breakpoints.tablet {
    margin-inline-start: var(--spacing-medium);
  }
}

.globeIconWrapper {
  display: flex;
  align-items: center;
  & > svg {
    height: calc(1.4 * var(--spacing-medium));
  }
  & > svg > path {
    stroke: var(--color-text-default);
  }
}

.triggerButton {
  border: 1px solid var(--color-text-default);
}

.triggerSuffixContainer {
  display: flex;
  position: relative;
  inset-block-end: 1px;
  transform: rotate(180deg);
}

.buttonWhite {
  svg > path {
    color: #fff;
    stroke: #fff;
  }
}